import { useState, useEffect, Fragment, useRef } from 'react'
import { Dialog, Transition, RadioGroup } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Helmet } from 'react-helmet';
import { ShieldCheckIcon, HandThumbUpIcon, CloudIcon, TrophyIcon, StarIcon, CheckBadgeIcon, CheckIcon, ArrowSmallRightIcon, PlusIcon, MinusIcon, ChevronDownIcon, ArrowLongRightIcon, ShoppingBagIcon, ShoppingCartIcon } from '@heroicons/react/24/solid';
import { Player } from 'video-react';
import video from '../L-Arginine_AllProduct_V3.mp4';
import n2o from '../n2osupport.jpg';
import ingredients from '../ingredients.jpg';
import exercise from '../exercise.jpg';
import hair from '../hair.jpg';
import bed from '../bed.jpg';
import bathroom from '../bathroom.jpg';
import couple from '../couple.jpg';
import jacked from '../jacked.jpg';


function SocialTextBanner(props) {
    const [one, setOne] = useState(0);
    const [two, setTwo] = useState(0);
    const [three, setThree] = useState(0);
    const [four, setFour] = useState(0);

    
    return (
        <div className='relative grid grid-cols-12 bg-[#EB3D44]' id='socialTextBanner'>
            <div className='col-span-1 col-start-5'>
                <h1 className='text-2xl text-white text-center font-bold' style={{opacity: `${one}%`} }>
                    Unleash
                </h1>
            </div>
            <div className='col-span-1'>
                <h1 className='text-2xl text-white text-center font-bold transition-opacity opacity-100 before:ease-in duration-300'>
                    Your
                </h1>
            </div>
            <div className='col-span-1'>
                <h1 className='text-2xl text-white text-center font-bold transition-opacity opacity-100 before:ease-in duration-400'>
                    Inner
                </h1>
            </div>
            <div className='col-span-1 col-start-8'>
                <h1 className='text-2xl text-white text-center font-bold transition-opacity opacity-100 before:ease-in duration-500'>
                    Athlete
                </h1>
            </div>

        </div>
    )
};

function USP() {
    return (
        <div className="bg-gradient-to-tr from-[#111829] via-[#111829] to-[#111829]/90">
            <div className="mx-auto max-w-2xl px-4 py-12 sm:px-6 sm:py-12 lg:max-w-7xl lg:px-8">
                <div className="grid grid-cols-1 gap-y-5 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 lg:gap-x-8">
                    <div className='mx-auto'>
                        <div className='h-24 w-24 mx-auto relative'>
                            <CloudIcon className='absolute h-full w-full  text-[#3A95D2] mx-auto'></CloudIcon>
                            <p className='absolute text-[#111829] inset-x-1/2 inset-y-1/2 transform -translate-x-4 -translate-y-2 font-bold'>N<sub>2</sub>O</p>
                            
                        </div>
                        {/*<img*/}
                        {/*    src='https://cdn.shopify.com/s/files/1/2002/2275/files/Havasu_-_Website_Product_Page_1st_batch_-61.png?v=10780402155233407524'*/}
                        {/*    alt=""*/}
                        {/*    className="h-24 w-auto mx-auto"*/}
                        {/*/>*/}
                        <h3 className="my-3 sm:my-3 text-lg font-medium text-white">Nitric Oxide Precursor</h3>
                        <p className="mt-2 text-sm text-gray-400 h-40 flex items-center">The capsules are formulated to support healthy nitric oxide production in the body. Increased nitric oxide levels could help promote blood vessel relaxation and improved blood flow, which may have benefits for cardiovascular health and exercise performance</p>
                    </div>
                    <div>
                        <TrophyIcon className='h-24 w-24  text-[#3A95D2] mx-auto' />
                        <h3 className="my-3 text-lg font-medium text-white">High-Quality Ingredients</h3>
                        <p className="mt-2 text-sm text-gray-400 h-40 flex items-center">Havasu Nutrition's L-Arginine Capsules feature quality L-Arginine sourced from trusted cGMP suppliers. The capsules are formulated using high-purity L-Arginine to ensure effectiveness and quality.</p>
                    </div>
                    <div>
                        <HandThumbUpIcon className='h-24 w-24  text-[#3A95D2] mx-auto' />
                        <h3 className="my-3 text-lg font-medium text-white">Convenient Dosage Form</h3>
                        <p className=" text-sm text-gray-400 h-40 flex items-center">The capsules may provide a convenient and easy-to-use dosage form. This allows users to incorporate L-arginine into their daily routine with ease, simplifying supplementation and ensuring consistent intake.</p>
                    </div>
                    <div>
                        <ShieldCheckIcon className='h-24 w-24  text-[#3A95D2] mx-auto' />
                        <h3 className="my-3 xs:my-3 text-lg font-medium text-white">Quality Assurance</h3>
                        <p className="text-sm text-gray-400 h-40 flex items-center">Havasu Nutrition emphasizes their commitment to quality assurance and product safety. This includes rigorous testing procedures, adherence to Good Manufacturing Practices (GMP), and quality control measures to ensure that the capsules meet high standards of purity, potency, and safety.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

//function UpsellOptions(props) {
//    const [productCount, setProductCount] = useState({
//        '40827052490796': 0,
//        '40827052523564': 0
//    })
//    const [focusVariants, setFocusVariants] = useState({
//        'Saw Palmetto': '40827052490796'
//    })
//    const [products] = useState(
//        [
//            {
//                name: 'Saw Palmetto',
//                options: ['100', '200'],
//                imageSrc: {
//                    '40827052490796': 'https://cdn.shopify.com/s/files/1/2002/2275/products/Saw-Palmetto-Capsules-Vitamins-Supplements.png?v=1669214362&width=1440',
//                    '40827052523564': 'https://cdn.shopify.com/s/files/1/2002/2275/products/Saw-Palmetto-Capsules-Vitamins-Supplements-8.png?v=1669214393&width=1440'
//                },
//                variants: {
//                    '100': '40827052490796',
//                    '200': '40827052523564'
//                },
//            }
//        ]
//    )
//    return (
//        <div>
//            <ul role="list" className="divide-y divide-gray-100">
//                {products.map((product) => (
//                    <li key={product.name} className="flex items-center justify-between gap-x-6 py-5">
//                        <div className="flex gap-x-4">
//                            <img className="h-24 w-24 flex-none rounded-md bg-gray-50" src={product.imageSrc[focusVariants[product.name]]} alt="" />
//                            <div className="min-w-0 flex items-center content-center">
//                                <p className="text-sm font-semibold leading-6 text-gray-900 align-middle">{product.name}</p>
//                                <p className="mt-1 truncate text-xs leading-5 text-gray-500"></p>
//                            </div>
//                        </div>
//                        <div className='flex'>
//                            <button
//                                className="rounded-l bg-white px-2.5 py-1 gap-x-0 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
//                            >
//                                <MinusIcon className='w-4 h-4' />
//                            </button>
//                            <input
//                                className="block rounded-none w-10 border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 text-center"
//                                value={productCount[focusVariants[product.name]]}
//                            />
//                            <button
//                                className="rounded-r bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
//                            >
//                                <PlusIcon className='w-4 h-4' />
//                            </button>
//                        </div>
//                    </li>
//                ))}
//            </ul>
//            <a
//                href="#"
//                className="flex w-full items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
//            >
//                View all
//            </a>
//        </div>
//    )
//}

function Product(props) {
    const [product] = useState(props.product);
    const [focusVariant, setFocusVariant] = useState(Object.keys(props.product.variants)[0]);
    const [descriptionOpen, setDescriptionOpen] = useState(false);
    const [mapItems] = useState(Object.keys(props.product.variants).length > 1 ? true : false)

    function addItem() {
        props.updateSelection({
            ...props.selections,
            [focusVariant]: props.selections[focusVariant] + 1
        })
    }
    function removeItem() {
        props.updateSelection({
            ...props.selections,
            [focusVariant]: props.selections[focusVariant] - 1
        })
    }
    return (
        <>
            <li className="flex items-center justify-between gap-x-6 py-2">
                <div className="flex gap-x-4">
                    <img className="h-24 w-24 flex-none rounded-md bg-gray-50" src={product.imageSrc[focusVariant]} alt="" />
                    <div className="min-w-0 flex items-center content-center">
                        <button className="text-sm font-semibold leading-6 text-gray-900 align-middle flex items-center" onClick={() => setDescriptionOpen(prev => !prev)}>
                            {product.name}
                            {descriptionOpen ? <MinusIcon className='w-4 h-4 ml-3' /> : <ChevronDownIcon className='w-4 h-4 ml-3' />}
                        </button>
                        <p className="mt-1 truncate text-xs leading-5 text-gray-500"></p>
                    </div>
                </div>
                <div className='flex'>
                    {mapItems ? Object.keys(product.variants).map(v => {
                        return (
                            <button
                                className={focusVariant === v ? 'bg-white rounded-full mx-2.5 px-2.5 py-1 gap-x-0 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50' : 'bg-gray-200 rounded-full px-2.5 py-1 gap-x-0 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'}
                                onClick={() => setFocusVariant(v)}
                            >{props.product.variants[v]}</button>
                        )
                    }) : null}
                </div>
                <div className='flex'>
                    <button
                        disabled={props.selections[focusVariant] === 0}
                        className="rounded-l-full disabled:pointer-events-none disabled:bg-gray-300 bg-white px-2.5 py-1 gap-x-0 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        onClick={removeItem}
                    >

                        <MinusIcon className='w-6 h-6' />

                    </button>
                    <input
                        className="block pointer-events-none rounded-none w-10 border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 text-center"
                        value={props.selections[focusVariant]}
                        readOnly
                    />
                    <button
                        className="rounded-r-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        onClick={addItem}
                    >
                        <PlusIcon className='w-6 h-6' />
                    </button>
                </div>
            </li>
            <div className={descriptionOpen ? 'text-sm text-start' : 'hidden'} style={{paddingLeft: '6rem', paddingRight: '9rem'}}>{product.description}</div>
        </>)
}
function UpsellOptions(props) {
    const [selections, setSelections] = useState(initialSelections());
    useEffect(() => {
        function updateSelections() {
            props.setSelections(selections);
        };
        updateSelections();
    }, [selections]);
    function initialSelections() {
        var init = {}
        for (const x of props.products) {
            for (const y in x.variants) {
                init[y] = 0
            }
        };
        return init
    };

    

    return (
        <>
            {props.products.map(x => {
                return (
                    <Product product={x} selections={selections} updateSelection={setSelections} />
                )
            })}
        </>
    )

}
function CTABanner(props) {
    return (
        <div className="relative isolate flex justify-center items-center h-24 gap-x-6 overflow-hidden bg-[#111829] px-6 py-2.5 sm:px-3.5">
            <div
                className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
                aria-hidden="true"
            >
                <div
                    className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
                    style={{
                        clipPath:
                            'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
                    }}
                />
            </div>
            <div
                className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
                aria-hidden="true"
            >
                <div
                    className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
                    style={{
                        clipPath:
                            'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
                    }}
                />
            </div>
            <div className="flex flex-wrap items-center gap-x-4 gap-y-2 justify-center">
                <p className="text-sm lg:text-lg leading-6 text-gray-100">
                    <strong className="font-bold">Be An Alpha</strong>
                    <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                        <circle cx={1} cy={1} r={1} />
                    </svg>
                    Stay Jacked with Havasu Nutrition L-Arginine Products
                </p>
                <button
                    href=""
                    onClick={props.openUpsell}
                    className="lg:flex-none rounded-md bg-[#348CC2] px-3.5 py-1 lg:text-lg font-semibold text-white shadow-sm hover:bg-white hover:text-[#348CC2] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                >
                    Buy Now <span aria-hidden="true">&rarr;</span>
                </button>
            </div>
            
        </div>
    )
}

function Review(props) {
    return (
        <div
            className='justify-center sm:w-3/4 lg:w-full rounded-3xl bg-gradient-to-br from-white via-gray-100 to-gray-300 w-1/2 shadow-md border border-gray-200/70 h-full'
        >
            <div className=' ml-3 mt-3'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-quote" viewBox="0 0 16 16">
                    <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z" />
                </svg>
            </div>
            <div
                className='text-center italic text-sm sm:text-md justify-center flex py-2 px-5 md:w-40 h-40 sm:h-32 align-middle'
            >
                {props.text}
            </div>
            <div className=' rotate-180 mr-3 mb-3'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-quote" viewBox="0 0 16 16">
                    <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z" />
                </svg>
            </div>
            <div
                className='bottom-0 static mb-3'
            >
                <div className='flex gap-x-0.5 h-5 text-yellow-400 justify-center items-center'>
                    <StarIcon className="h-5 w-5 flex-none" aria-hidden="true" />
                    <StarIcon className="h-5 w-5 flex-none" aria-hidden="true" />
                    <StarIcon className="h-5 w-5 flex-none" aria-hidden="true" />
                    <StarIcon className="h-5 w-5 flex-none" aria-hidden="true" />
                    <StarIcon className="h-5 w-5 flex-none" aria-hidden="true" />
                </div>
                <div className='inline-flex items-center transform -translate-x-2 text-[#348CC2]'>
                    <CheckBadgeIcon className='h-5 w-5 flex-none self-center m-2' /> Verified Buyer
                </div>
            </div>
        </div>
    )
}

function Reviews(props) {
    //const [reviews] = useState([
    //    "I've been using Havasu Nutrition's L-arginine for a few months now, and it has made a noticeable difference in my athletic performance. I feel like I have more endurance during workouts and faster recovery afterward. Highly recommended for anyone looking to take their athletic performance to the next level!",
    //    "I started taking L-Arginine to support my cardio workouts, and I'm amazed at the results. My energy levels have increased, and I can push myself harder during intense training sessions. I've noticed improved blood flow, and my muscles feel more pumped. It's become a staple in my supplement regimen.",
    //    "As a competitive runner, I'm always looking for ways to improve my performance. Incorporating L-arginine into my training routine has been a game-changer. It helps me maintain optimal blood flow, allowing me to run longer distances without feeling fatigued. I've seen improvements in my race times, and I feel more confident in my abilities.",
    //    "L-Arginine has become my go-to supplement for athletic support. Since I started taking it, my workouts have been more intense and focused. I feel a significant boost in my endurance, allowing me to push through tough training sessions. It's a must-have for any serious athlete.",
    //    "I've been using L-Arginine from Havasu Nutrition to support my weightlifting routine, and it has exceeded my expectations. The increased blood flow helps deliver oxygen and nutrients to my muscles, giving me better pumps and improved strength. I've noticed faster muscle recovery, which means I can hit the gym harder and more frequently.",
    //    "I started taking L-arginine for its potential benefits on men's health, and I'm thrilled with the results. Not only have I experienced enhanced erectile function, but I've also noticed a boost in my energy levels and overall well-being. It's been a game-changer for my vitality and quality of life.",
    //    "As I entered my 40s, I started experiencing some concerns related to men's health. After researching and trying different supplements, L-arginine from Havasu Nutrition stood out. It has helped me improve blood flow and maintain healthy cardiovascular function. Additionally, it has provided a natural boost to my libido. I'm impressed with the positive impact it has had on my overall men's health.",
    //    "I was looking for a natural solution to support my men's health, and L-arginine has been a fantastic find. It has improved blood flow, which has had positive effects on my performance and overall vitality. It's a safe and effective supplement that I would recommend to any man who wants to take control of his well-being."
    //])
    const [reviews] = useState([
        "I've been using Havasu Nutrition's L-arginine for a few months now, and it has made a noticeable difference in my athletic performance. I feel like I have more endurance during workouts and faster recovery afterward. Highly recommended for anyone looking to take their athletic performance to the next level!",
        "I started taking L-Arginine to support my cardio workouts, and I'm amazed at the results. My energy levels have increased, and I can push myself harder during intense training sessions. I've noticed improved blood flow, and my muscles feel more pumped. It's become a staple in my supplement regimen.",
        "As a competitive runner, I'm always looking for ways to improve my performance. Incorporating L-arginine into my training routine has been a game-changer. It helps me maintain optimal blood flow, allowing me to run longer distances without feeling fatigued. I've seen improvements in my race times, and I feel more confident in my abilities."
    ])
    return (
        <div
            className='w-full py-10 grid lg:grid-cols-3 grid-cols-1 justify-items-center gap-x-6 gap-y-5 px-5 bg-gradient-to-tr from-[#111829] from-10% via-[#111829] via-98% to-[#111829]/90 to-99%'
        >
            {reviews.map(r => <Review text={r} />) }
        </div>
    )
}

function Upsell(props) {
    const [open, setOpen] = useState(props.open);

    const cancelButtonRef = useRef(null);
    const [selections, setSelections] = useState({})
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    };

    function checkOut() {
        var items = [];
        for (const x in selections) {
            if (selections[x] !== 0) {
                items.push(`${x}:${selections[x]}`)
            }
        };
        var newURL = `${props.baseURL},${items.join(',')}`
        window.open(newURL,'_self');

    }
    useEffect(() => {
        setOpen(props.open);
    }, [props.open])

    useEffect(() => {
        if (!open) {
            props.openUpsell();
        }
    }, [open]);

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-20" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                            enterTo="opacity-100 translate-y-0 md:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 md:scale-100"
                            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                        >
                            <Dialog.Panel className="flex transform justify-center text-left rounded-lg text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl font-havasu">
                                <div className='rounded-2xl p-3 bg-white'>
                                    <h1 className='text-xl text-center'>Other Products:</h1>
                                    <UpsellOptions
                                        products={[{
                                                name: 'Saw Palmetto',
                                                variants: {
                                                    '40827052490796': '100 ct.',
                                                    '40827052523564': '200 ct.'
                                                },
                                                description: "As men age, prostate health becomes more important. We've made it easy for men to take one capsule a day to reduce symptoms of enlarged prostate.You can even pair this daily supplement with our nighttime saw palmetto to help reduce urination frequency at night.",
                                                imageSrc: {
                                                    '40827052490796': 'https://cdn.shopify.com/s/files/1/2002/2275/products/Saw-Palmetto-Capsules-Vitamins-Supplements.png?v=1669214362&width=1440',
                                                    '40827052523564': 'https://cdn.shopify.com/s/files/1/2002/2275/products/Saw-Palmetto-Capsules-Vitamins-Supplements-8.png?v=1669214393&width=1440'
                                                }
                                            },
                                            {
                                                name: 'L-Arginine + Pine Bark Capsules',
                                                variants: {
                                                    '40821380546604': '90 ct.'
                                                },
                                                description: "Pine bark has been found to have antioxidant and anti-inflammatory effects on our bodies. Help your body maintain its vigor with a nitric oxide supplement that helps give you impressive vascular health and muscle recovery. Havasu Nutrition utilizes French pine bark extract that helps focus and calm the mind, maximizing its full potential all while fueling your energy bank!",
                                                imageSrc: {
                                                    '40821380546604': 'https://cdn.shopify.com/s/files/1/2002/2275/products/L-Arginine-Pine-Bark-Capsules-90ct-capsule-supplement.png?v=1668868628&width=1440',
                                                                                                    }
                                            }]}
                                        setSelections={setSelections}
                                    />
                                    <div className='flex justify-center'>
                                        <button
                                            className='flex justify-center p-3 text-white items-center rounded-md text-md bg-[#348CC2]'
                                            onClick={() => checkOut()}
                                        >
                                            Checkout <ShoppingBagIcon className='w-5 h-5 ml-3 text-white' />
                                        </button>
                                        
                                    </div>
                                    <div className='flex justify-center my-3'>
                                        <button
                                            className='flex text-center underline-[#348CC2] underline text-[#348CC2]'
                                            onClick={props.openUpsell}
                                        >Back</button>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

function Footer(props) {
    const navigation = {
        main: [
            { name: 'Our Story', href: 'https://havasunutrition.com/pages/our-story' },
            { name: 'Blog', href: 'https://havasunutrition.com/blogs/blog' },
            { name: "FAQ's", href: 'https://havasunutrition.com/pages/faqs' },
            { name: 'Privacy Policy', href: 'https://havasunutrition.com/pages/privacy-policy' },
            { name: 'Terms of Use', href: 'https://havasunutrition.com/pages/terms-conditions' },
            { name: 'Contact Us', href: 'https://havasunutrition.com/pages/contact-us' },
        ],
        social: [
            {
                name: 'Instagram',
                href: 'https://www.instagram.com/havasunutrition/',
                icon: (props) => (
                    <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                        <path
                            fillRule="evenodd"
                            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                            clipRule="evenodd"
                        />
                    </svg>
                ),
            },
            
            
            {
                name: 'Pinterest',
                href: 'https://www.pinterest.com/havasunutrition/',
                icon: (props) => (
                    <svg fill='currentColor' className="icon icon-pinterest" {...props} viewBox="0 0 17 18">
                        <path d="M8.48.58a8.42 8.42 0 015.9 2.45 8.42 8.42 0 011.33 10.08 8.28 8.28 0 01-7.23 4.16 8.5 8.5 0 01-2.37-.32c.42-.68.7-1.29.85-1.8l.59-2.29c.14.28.41.52.8.73.4.2.8.31 1.24.31.87 0 1.65-.25 2.34-.75a4.87 4.87 0 001.6-2.05 7.3 7.3 0 00.56-2.93c0-1.3-.5-2.41-1.49-3.36a5.27 5.27 0 00-3.8-1.43c-.93 0-1.8.16-2.58.48A5.23 5.23 0 002.85 8.6c0 .75.14 1.41.43 1.98.28.56.7.96 1.27 1.2.1.04.19.04.26 0 .07-.03.12-.1.15-.2l.18-.68c.05-.15.02-.3-.11-.45a2.35 2.35 0 01-.57-1.63A3.96 3.96 0 018.6 4.8c1.09 0 1.94.3 2.54.89.61.6.92 1.37.92 2.32 0 .8-.11 1.54-.33 2.21a3.97 3.97 0 01-.93 1.62c-.4.4-.87.6-1.4.6-.43 0-.78-.15-1.06-.47-.27-.32-.36-.7-.26-1.13a111.14 111.14 0 01.47-1.6l.18-.73c.06-.26.09-.47.09-.65 0-.36-.1-.66-.28-.89-.2-.23-.47-.35-.83-.35-.45 0-.83.2-1.13.62-.3.41-.46.93-.46 1.56a4.1 4.1 0 00.18 1.15l.06.15c-.6 2.58-.95 4.1-1.08 4.54-.12.55-.16 1.2-.13 1.94a8.4 8.4 0 01-5-7.65c0-2.3.81-4.28 2.44-5.9A8.04 8.04 0 018.48.57z">
                        </path></svg>
                ),
            },
        ],
    }
    return (
        <footer className="bg-[#348CC2] grid grid-cols-12">
            <a href='https://havasunutrition.com' className='flex w-full pt-5 col-span-2 pl-3'>
                <svg className="icon-logo flex w-full" width="136" height="27" viewBox="0 0 136 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M35.551 24.67h.024v-5.925h1.117v7.412h-1.409l-4.222-6.051h-.024v6.051h-1.116v-7.412h1.457l4.173 5.925zM43.731 23.412v-4.667h1.117v4.606c0 1.015.498 2.063 1.988 2.063s1.984-1.048 1.984-2.063v-4.606h1.117v4.667c0 1.52-.992 2.932-3.105 2.932-2.109 0-3.1-1.412-3.1-2.932zM59.799 26.157h-1.117v-6.5h-2.564v-.912h6.244v.912H59.8v6.5zM69.675 26.157h-1.117v-7.412h2.564c1.548 0 2.846.543 2.846 2.053 0 1.09-.8 1.782-1.94 1.96l2.233 3.404h-1.356l-2.032-3.297h-1.198v3.292zm0-4.166h1.289c1.05 0 1.873-.304 1.873-1.193 0-.888-.824-1.174-1.797-1.174h-1.365v2.367zM81.75 26.157h-1.116v-7.412h1.116v7.412zM91.617 26.157h-1.116v-6.5h-2.564v-.912h6.244v.912h-2.564v6.5zM101.498 26.157h-1.117v-7.412h1.117v7.412zM112.381 26.344c-2.425 0-4.222-1.622-4.222-3.905 0-2.314 1.792-3.886 4.222-3.886 2.439 0 4.245 1.572 4.245 3.886 0 2.283-1.806 3.905-4.245 3.905zm0-6.879c-1.84 0-3.015 1.352-3.015 2.974 0 1.656 1.184 2.975 3.015 2.975 1.854 0 3.038-1.319 3.038-2.975 0-1.622-1.179-2.974-3.038-2.974zM128.913 24.67h.024v-5.925h1.116v7.412h-1.409l-4.221-6.051h-.024v6.051h-1.117v-7.412h1.457l4.174 5.925z" fill="#fff"></path>
                    <path d="M23.562.787h-5.449v25.37h5.449V.787z" fill="#fff"></path>
                    <path d="M14.505.787H9.057V18.91h5.448V.787z" fill="#fff"></path>
                    <path d="M5.448.787H0V11.66h5.448V.787z" fill="#fff"></path>
                    <path d="M29.92.853h2.847v5.99h7.48V.853h2.847v14.824h-2.846V9.102h-7.48v6.575H29.92V.853zM54.403.853h2.463l6.89 14.824h-3.253l-1.49-3.39h-6.939l-1.447 3.39H47.44L54.403.853zm3.618 9.17-2.463-6.032-2.507 6.032h4.97zM64.81.853h3.188l4.403 11.41h.091L76.944.853h3.072l-6.417 14.824h-2.463L64.811.853zM87.894.853h2.463l6.89 14.824h-3.253l-1.49-3.39h-6.939l-1.447 3.39H80.93L87.894.853zm3.613 9.17-2.463-6.032-2.506 6.032h4.969zM110.033 3.953c-.609-.734-1.807-1.277-2.938-1.277-1.308 0-2.822.585-2.822 2.03 0 1.36 1.241 1.758 3.052 2.282 2.056.585 4.677 1.422 4.677 4.335 0 3.185-2.688 4.733-5.832 4.733-2.079 0-4.226-.734-5.491-2.114l2.036-1.758c.791 1.005 2.238 1.632 3.503 1.632 1.332 0 2.895-.608 2.895-2.198 0-1.548-1.673-1.988-3.685-2.577-1.946-.566-4.045-1.445-4.045-4.209 0-3.016 2.962-4.354 5.851-4.354 1.606 0 3.436.524 4.653 1.59l-1.854 1.885zM130.053 10.234c0 3.582-2.779 5.822-6.44 5.822-3.661 0-6.44-2.24-6.44-5.822V.853h2.846v9.278c0 1.716.858 3.559 3.594 3.559 2.736 0 3.594-1.843 3.594-3.56V.854h2.846v9.38zM134.5 18.553c.829 0 1.5.646 1.5 1.455s-.671 1.445-1.5 1.445-1.5-.636-1.5-1.445c0-.81.671-1.455 1.5-1.455zm0 2.69c.695 0 1.26-.557 1.26-1.23 0-.679-.565-1.23-1.26-1.23-.695 0-1.26.551-1.26 1.23-.005.673.561 1.23 1.26 1.23zm-.584-2.058h.594c.316 0 .623.126.623.481 0 .239-.154.393-.388.445l.455.72h-.369l-.393-.688h-.216v.688h-.302v-1.646h-.004zm.546.715c.172 0 .369-.047.369-.243 0-.178-.178-.224-.336-.224h-.278v.467h.245z" fill="#fff"></path>
                </svg>
            </a>
            <div className="mx-auto max-w-7xl overflow-hidden px-6 py-5 sm:py-5 lg:px-8 col-span-8">
                <nav className="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12" aria-label="Footer">
                    {navigation.main.map((item) => (
                        <div key={item.name} className="pb-6">
                            <a href={item.href} className="text-sm leading-6 text-white hover:text-gray-200">
                                {item.name}
                            </a>
                        </div>
                    ))}
                </nav>
                
            </div>
            <div className="mt-5 flex justify-center space-x-10 col-span-1 mr-3">
                {navigation.social.map((item) => (
                    <a key={item.name} href={item.href} className="text-white hover:text-gray-200">
                        <span className="sr-only">{item.name}</span>
                        <item.icon className="h-6 w-6" aria-hidden="true" />
                    </a>
                ))}
            </div>
            <p className="text-center text-xs leading-5 text-white col-span-12">
                © Havasu Nutrition, 2023. All Rights Reserved.
            </p>
            </footer>
    )
}

function Benefits(props) {
    const [features] = useState(
        [
            {
                name: 'Supports Healthy Blood Flow',
                description: "L-Arginine plays a role in the production of nitric oxide, which helps to promote the relaxation and dilation of blood vessels, thereby supporting healthy blood circulation.",
                imageSrc: 'https://dev.growve.tools:8080/content/havasu/n2osupport.jpg',
                imageAlt: ''
            },
            {
                name: 'Promotes Cardiovascular Health',
                description: "Havasu Nutrition's L- Arginine Capsules feature quality L - Arginine sourced from trusted cGMP suppliers.The capsules are formulated using high - purity L - Arginine to ensure effectiveness and quality.",
                imageSrc: 'https://dev.growve.tools:8080/content/havasu/ingredients.jpg',
                imageAlt: ''
            },
            {
                name: 'Supports Exercise Performance',
                description: "L-Arginine may contribute to improved exercise capacity and performance by enhancing blood flow, oxygen delivery, and nutrient transport to muscles.",
                imageAlt: '',
                imageSrc: 'https://dev.growve.tools:8080/content/havasu/exercise.jpg'
            }
        ]
    )

    

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <div className="bg-gradient-to-br from-[#348CC2]/80 via-[#348CC2]/60 to-[#348CC2]/30">
            <div className="mx-auto max-w-2xl px-4 py-5 sm:px-6 sm:py-5 lg:max-w-7xl lg:px-8">

                <div className="space-y-16">
                    {features.map((feature, featureIdx) => (
                        <div
                            key={feature.name}
                            className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center lg:gap-x-8"
                        >
                            <div
                                className={classNames(
                                    featureIdx % 2 !== 0 ? 'lg:col-start-1' : 'lg:col-start-8 xl:col-start-9',
                                    'mt-6 lg:col-span-5 lg:row-start-1 lg:mt-0 xl:col-span-4'
                                )}
                            >
                                <h3 className="text-lg font-bold text-[#474141]">{feature.name}</h3>
                                <p className="mt-2 text-sm text-[#474141]">{feature.description}</p>
                            </div>
                            <div
                                className={classNames(
                                    featureIdx % 2 !== 0 ? 'lg:col-start-6 xl:col-start-5' : 'lg:col-start-1',
                                    'flex-auto lg:col-span-7 lg:row-start-1 xl:col-span-8'
                                )}
                            >
                                <div className="aspect-h-2 aspect-w-5 overflow-hidden rounded-lg bg-gray-100">
                                    <img src={feature.imageSrc} alt={feature.imageAlt} className="object-cover object-center" />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

function CTA(props) {
    /* THIS HAS UNAPPROVED COPY */
    return (
        

        <div className="relative isolate overflow-hidden bg-[#111829] px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
                    
                    <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
                        <h2 className="text-3xl font-bold tracking-tight text-center text-white sm:text-4xl">
                            Get Jacked.
                            
                            <br />
                            Stay Jacked.
                        </h2>
                        <p className="mt-6 text-xl text-center leading-8 text-gray-300">
                    <b>A powerhouse move for alpha males:</b> <br /> Don't let yourself go soft - Stay jacked with Havasu Nutrition L-Arginine Products.
                        </p>
                        <div className="mt-10 flex items-center justify-center gap-x-6">
                            <button
                                onClick={props.openUpsell}
                                className="rounded-md bg-white justify-center items-center flex px-8 py-2.5 text-xl font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                            >
                                Buy Now <ShoppingCartIcon className='w-5 h-5 ml-3' />
                            </button>
                        </div>
                    </div>
                    <div className="relative mt-16 h-80 lg:mt-8">
                        <img
                            className="absolute left-0 top-0 w-[75rem] max-w-none object-none object-left rounded-md bg-white/5 ring-1 ring-white/10"
                            src='https://dev.growve.tools:8080/content/havasu/jacked.jpg'
                            alt="App"
                            width={600}
                            height={400}
                        />
                    </div>
                </div>
            
        
    )
};

function Video(props) {
    const isMuted = useRef(true);
    const auto = useRef(true);
    return (
        <div className='relative isolate flex z-0 bg-none  w-full h-full'
        >
            <video
                loop
                autoPlay
                onCanPlay={(e) => { e.target.muted=true; e.target.play()}}
                id='hero-video'
                src={video}
            >
            </video>
        </div>
    )
}
export default function Hero(props) {
    const [openUpsell, setOpenUpsell] = useState(false);
    const [permalink, setPermalink] = useState('https://havasunutrition.com/cart/40825506005036:1');
    const [descriptions] = useState([
        'Boost your performance with L-arginine!',
        'Unlock your potential with L-arginine supplementation.',
        'Enhance your workouts with the power of L-arginine.',
    ]);

    return (
        <>
            <div className="relative z-20 isolate flex justify-center items-center mx-auto gap-x-6 overflow-hidden bg-[#348CC2] px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
                <a href='https://havasunutrition.com' className='flex w-full'>
                    <svg className="icon-logo flex w-full" width="136" height="27" viewBox="0 0 136 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M35.551 24.67h.024v-5.925h1.117v7.412h-1.409l-4.222-6.051h-.024v6.051h-1.116v-7.412h1.457l4.173 5.925zM43.731 23.412v-4.667h1.117v4.606c0 1.015.498 2.063 1.988 2.063s1.984-1.048 1.984-2.063v-4.606h1.117v4.667c0 1.52-.992 2.932-3.105 2.932-2.109 0-3.1-1.412-3.1-2.932zM59.799 26.157h-1.117v-6.5h-2.564v-.912h6.244v.912H59.8v6.5zM69.675 26.157h-1.117v-7.412h2.564c1.548 0 2.846.543 2.846 2.053 0 1.09-.8 1.782-1.94 1.96l2.233 3.404h-1.356l-2.032-3.297h-1.198v3.292zm0-4.166h1.289c1.05 0 1.873-.304 1.873-1.193 0-.888-.824-1.174-1.797-1.174h-1.365v2.367zM81.75 26.157h-1.116v-7.412h1.116v7.412zM91.617 26.157h-1.116v-6.5h-2.564v-.912h6.244v.912h-2.564v6.5zM101.498 26.157h-1.117v-7.412h1.117v7.412zM112.381 26.344c-2.425 0-4.222-1.622-4.222-3.905 0-2.314 1.792-3.886 4.222-3.886 2.439 0 4.245 1.572 4.245 3.886 0 2.283-1.806 3.905-4.245 3.905zm0-6.879c-1.84 0-3.015 1.352-3.015 2.974 0 1.656 1.184 2.975 3.015 2.975 1.854 0 3.038-1.319 3.038-2.975 0-1.622-1.179-2.974-3.038-2.974zM128.913 24.67h.024v-5.925h1.116v7.412h-1.409l-4.221-6.051h-.024v6.051h-1.117v-7.412h1.457l4.174 5.925z" fill="#fff"></path>
                        <path d="M23.562.787h-5.449v25.37h5.449V.787z" fill="#fff"></path>
                        <path d="M14.505.787H9.057V18.91h5.448V.787z" fill="#fff"></path>
                        <path d="M5.448.787H0V11.66h5.448V.787z" fill="#fff"></path>
                        <path d="M29.92.853h2.847v5.99h7.48V.853h2.847v14.824h-2.846V9.102h-7.48v6.575H29.92V.853zM54.403.853h2.463l6.89 14.824h-3.253l-1.49-3.39h-6.939l-1.447 3.39H47.44L54.403.853zm3.618 9.17-2.463-6.032-2.507 6.032h4.97zM64.81.853h3.188l4.403 11.41h.091L76.944.853h3.072l-6.417 14.824h-2.463L64.811.853zM87.894.853h2.463l6.89 14.824h-3.253l-1.49-3.39h-6.939l-1.447 3.39H80.93L87.894.853zm3.613 9.17-2.463-6.032-2.506 6.032h4.969zM110.033 3.953c-.609-.734-1.807-1.277-2.938-1.277-1.308 0-2.822.585-2.822 2.03 0 1.36 1.241 1.758 3.052 2.282 2.056.585 4.677 1.422 4.677 4.335 0 3.185-2.688 4.733-5.832 4.733-2.079 0-4.226-.734-5.491-2.114l2.036-1.758c.791 1.005 2.238 1.632 3.503 1.632 1.332 0 2.895-.608 2.895-2.198 0-1.548-1.673-1.988-3.685-2.577-1.946-.566-4.045-1.445-4.045-4.209 0-3.016 2.962-4.354 5.851-4.354 1.606 0 3.436.524 4.653 1.59l-1.854 1.885zM130.053 10.234c0 3.582-2.779 5.822-6.44 5.822-3.661 0-6.44-2.24-6.44-5.822V.853h2.846v9.278c0 1.716.858 3.559 3.594 3.559 2.736 0 3.594-1.843 3.594-3.56V.854h2.846v9.38zM134.5 18.553c.829 0 1.5.646 1.5 1.455s-.671 1.445-1.5 1.445-1.5-.636-1.5-1.445c0-.81.671-1.455 1.5-1.455zm0 2.69c.695 0 1.26-.557 1.26-1.23 0-.679-.565-1.23-1.26-1.23-.695 0-1.26.551-1.26 1.23-.005.673.561 1.23 1.26 1.23zm-.584-2.058h.594c.316 0 .623.126.623.481 0 .239-.154.393-.388.445l.455.72h-.369l-.393-.688h-.216v.688h-.302v-1.646h-.004zm.546.715c.172 0 .369-.047.369-.243 0-.178-.178-.224-.336-.224h-.278v.467h.245z" fill="#fff"></path>
                    </svg> 
                </a>
            </div>
            <Video />
            <CTA openUpsell={() => setOpenUpsell(true)} />
            <Reviews />
            <Benefits />
            <USP />
            
            <Upsell baseURL={permalink} open={openUpsell} openUpsell={() => setOpenUpsell(false)} href={permalink} addToCart={setPermalink} />
            <Footer />
        </>
    )
}


//export default function Hero(props) {EB3D44
//    const [videoHeight, setVideoHeight] = useState(null);

//    useEffect(() => {
//        function getVideoHeight() {
//            var videoElement = document.getElementById('hero-video');
//            console.log(videoElement.offsetHeight);
//        };
//        getVideoHeight();
//    })
//    return (
//        <div className="bg-[#348CC2] overflow-hidden w-screen h-screen">
            
//                <div className="relative z-10 lg:w-1/4 h-fit">
                    //<svg
                    //    className="absolute inset-y-0 right-3 h-screen hidden w-80 translate-x-1/2 transform fill-[#668CC2] lg:block"
                    //    viewBox="0 0 100 100"
                    //    preserveAspectRatio="none"
                    //    aria-hidden="true"
                    //>
                    //    <polygon points="0,0 70,0 50,100 0,100" />
                    //</svg>

//                    <div className="relative overflow-hidden lg:pr-0 bg-[#348CC2] flex items-center">
//                        <div className="mx-auto lg:mx-0 overflow-hidden">
//                        <div className='flex justify-center items-center h-full ml-6 pb-5 overflow-hidden'>
                            //{/*<h1 className="text-2xl font-bold tracking-tight text-white sm:text-6xl overflow-hidden w-full">*/}
                            //{/*    Unleash Your Inner Athlete*/}
                            //{/*</h1>*/}
//                        </div>
//                        </div>
//                    </div>
//                </div>
            
//            <div className="bg-[#348CC2] overflow-hidden lg:absolute lg:inset-y-0 lg:-right-10 lg:w-10/12 lg:pl-10 h-fit w-screen">
                //<video autoPlay loop muted className='relative overflow-hidden w-screen' id='hero-video'>
                //    <source className='overflow-hidden' src={video} type='video/mp4' />
                //</video>
//            </div>
//        </div>
//    )
//}
