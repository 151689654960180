import { useState, useEffect } from 'react'
import { Disclosure, RadioGroup, Tab } from '@headlessui/react'
import { StarIcon } from '@heroicons/react/20/solid'
import { HeartIcon, MinusIcon, PlusIcon, MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline';
import getIcons from './SocialIcons';
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class textCheck {
    constructor(replacements, masterString, indexAdjustment = 0) {
        this.original = masterString;
        this.adjust = indexAdjustment;
        this.replacements = replacements;
        this.narrow();
        this.prepare();
    };

    index(num) {
        return num - this.adjust;
    }

    narrow() {
        for (const x of this.replacements) {
            if (this.original.includes(x.text) && this.original.slice(this.index(x.index), this.index(x.index + x.text.length)) === x.text) {
                x.use = true;
            } else {
                x.use = false;
            }
        };
        let replacements = this.replacements.filter(x => x.use === true);
        this.replacements = replacements;
    };

    prepare() {
        if (this.replacements.length === 0) {
            this.values = [this.original]
        } else {
            this.clean()
        }
    };

    clean() {
        var values = [];
        for (const x of this.replacements) {
            values.push(this.original.slice(0, this.index(x.index)))
            values.push(x);
            let nextReplacements = this.replacements.slice(1);
            let moreValues = new textCheck(nextReplacements, this.original.slice(this.index(x.index + x.text.length)), x.index+x.text.length)
            values = [...values, ...moreValues.values]
        }
        this.values = values;
    }
}
function FocusProduct(props) {

    return (
        <div id={props.id} className="bg-white">
            <div className="mx-auto max-w-2xl px-4 py-6 sm:px-12 sm:py-12 lg:max-w-7xl lg:px-24">
                <h1 className="text-3xl font-bold tracking-tight text-gray-900 py-8">{props.product.name}</h1>
                <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8 content-center">
                    {/* Image gallery */}
                    <div className='aspect-h-1 aspect-w-1 w-full'>
                        <img
                            src={`https://api.growve.tools:8393/landingPageImages/${props.product.image}`}
                            alt="product"
                            className="h-full w-full object-cover object-center sm:rounded-lg"
                        />
                    </div>

                    {/* Product info */}
                    <div className="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0 self-center">
                        

                        <div className="mt-6">
                            <h3 className="sr-only">Description</h3>

                            <div
                                className="space-y-6 text-base text-gray-700"
                            >

                                <TextParser text={props.product.description} replacements={props.hyperlinks} />
                            </div>
                        </div>

                        

                        <section aria-labelledby="details-heading" className="mt-12">
                            <h2 id="details-heading" className="sr-only">
                                Additional details
                            </h2>

                                         
                                <div className="prose prose-sm py-4">
                                <ul className='list-disc pl-10 justify-center' style={{listStyle: 'disc'} }>
                                        {props.product.details.map((item) => (
                                            <li className='text-start py-1.5 font-medium' key={item}><TextParser text={item} replacements={props.hyperlinks} /></li>
                                        ))}
                                    </ul>
                                </div>      
                            
                        </section>
                        <div className="mt-10 flex justify-center">
                            <a
                                href={props.buttonLink}
                                style={{ backgroundColor: props.buttonColor }}
                                className="flex max-w-xs flex-1 items-center justify-center rounded-md border border-transparent px-8 py-3 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50 sm:w-full"
                            >
                                {props.buttonText} &rarr;
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

function Hyperlink(props) {
    return (
        <a href={props.href} rel='noopener noreferrer' target='_blank' className='text-blue-400 underline underline-blue-400 gap-0 p-0 m-0'>{props.text}</a>
    )
}


function TextParser(props) {
    const [textArray, setTextArray] = useState(new textCheck(props.replacements, props.text).values);
    console.log(textArray)
    return (
        <div>
            {textArray.map(t => {
                if (typeof t === 'string') {
                    return <>{t}</>;
                } else {
                    return <Hyperlink {...t} />
                }
            }) }
        </div>
    )
}

function BenefitImageBlock(props) {
    return (
        <div id={props.id} className='grid lg:grid-cols-2 sm:grid-cols-1 items-center px-12 lg:px-24'>
            <div className='p-6'>
                <img src={`https://api.growve.tools:8393/landingPageImages/${props.src}`} alt='' className='rounded-xl' />
            </div>
            <div>
                {props.benefits.map(benefit => {
                    return (
                        <>
                            <h3 className='font-bold text-3xl text-center pb-3 px-6'>{benefit.title}</h3>
                            <p className='text-start text-lg px-6'>{benefit.text}</p>
                        </>
                ) }) }
            </div>
        </div>
    )
};

function IconsRow(props) {
    if (props.icons.length === 3) {
        return (
            <div className="mt-16 grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-3 px-12 lg:px-24">
                {props.icons.map((icon) => (
                    <div key={icon.name} className="grid grid-cols-1">
                        <div className="sm:flex-shrink-0 justify-self-center">
                            <img className="h-24 w-24" src={`https://api.growve.tools:8393/landingPageImages/${icon.src}`} alt="" />
                        </div>
                        <div className="mt-4 sm:ml-6 sm:mt-0 lg:ml-0 lg:mt-6 justify-self-center">
                            <h3 className="text-xl font-medium text-gray-900">{icon.name}</h3>
                        </div>
                    </div>
                ))}
            </div>
        );
    } else if (props.icons.length === 2) {
        return (
            <div className="mt-16 grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-2 px-12 lg:px-24">
                {props.icons.map((icon) => (
                    <div key={icon.name} className="grid grid-cols-1">
                        <div className="sm:flex-shrink-0 justify-self-center">
                            <img className="h-24 w-24" src={`https://api.growve.tools:8393/landingPageImages/${icon.src}`} alt="" />
                        </div>
                        <div className="mt-4 sm:ml-6 sm:mt-0 lg:ml-0 lg:mt-6 justify-self-center">
                            <h3 className="text-xl font-medium text-gray-900">{icon.name}</h3>
                        </div>
                    </div>
                ))}
            </div>
        );
    } else if (props.icons.length === 1) {
        return (
            <div className="mt-16 grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-1 px-12 lg:px-24">
                {props.icons.map((icon) => (
                    <div key={icon.name} className="grid grid-cols-1">
                        <div className="sm:flex-shrink-0 justify-self-center">
                            <img className="h-24 w-24" src={`https://api.growve.tools:8393/landingPageImages/${icon.src}`} alt="" />
                        </div>
                        <div className="mt-4 sm:ml-6 sm:mt-0 lg:ml-0 lg:mt-6 justify-self-center">
                            <h3 className="text-xl font-medium text-gray-900">{icon.name}</h3>
                        </div>
                    </div>
                ))}
            </div>
        );
    } else if (props.icons.length === 0) {
        return;
    };
    
}


function Icons(props) {
    const [icons] = useState(separateIcons(props.icons));
    const [headerClass, setHeaderClass] = useState(`text-3xl p-4 font-bold tracking-tight ${props.headerColor}`);

    function separateIcons(array) {
        var newArray = []
        for (let x = 0; x < array.length; x += 3) {
            let start = x;
            let end = x + 3;
            newArray.push(array.slice(start, end))
        };
        return newArray;
    }
    return (
        <div id={props.id} className='w-full'>
            <div className="mx-auto w-full">
                <div className='mx-auto w-full'>
                    <h2 className={headerClass} style={{ backgroundColor: props.headerBg }}>
                        {props.headerText}
                    </h2>
                    {icons.map(x => {
                        return (
                            <IconsRow icons={x} />
                        )
                    }) }
                </div>
            </div>
        </div>
    )
};

function LogoBanner(props) {
    return (
        <div id={props.id} className="relative z-20 isolate flex justify-center items-center mx-auto gap-x-6 overflow-hidden px-6 py-2.5 sm:px-3.5 sm:before:flex-1" style={{backgroundColor: props.bgColor} }>
            <a href={props.homePage} className='flex w-full items-center justify-center'>
                <img src={`https://api.growve.tools:8393/landingPageImages/${props.logo}`} alt='' style={{ maxHeight: props.logoHeight }} />
            </a>
        </div>
    )
};

function CallToAction(props) {
    return (
        <div id={props.id} className='grid lg:grid-cols-2 grid-cols-1 mx-16 items-center py-12 px-12 lg:px-24'>
            <div className="mx-auto max-w-2xl text-3xl lg:col-span-2 col-span-1 font-bold tracking-tight text-black sm:text-4xl pr-6">
                {props.title}
            </div>
            <div>
                <div className="mx-auto mt-6 max-w-xl font-medium text-lg leading-8 text-gray-500 text-center pr-6 pb-6">
                    {props.text}
                </div>
                <a className='font-bold text-lg rounded-lg text-white p-3 m-6 my-6' href={props.buttonLink} style={{ backgroundColor: props.buttonColor }}>{props.buttonText} &rarr;</a>
            </div>
            <div className='p-6' >
                <img className='rounded-xl border border-gray-300' src={`https://api.growve.tools:8393/landingPageImages/${props.src}`} alt='' />
            </div>
        </div>
    )
};

function ThreeReviewBlock(props) {
    return (
        <div id={props.id} className='grid lg:grid-cols-3 sm:grid-cols-1 items-start justify-center gap-x-6 p-12 lg:px-24'>
            <div className='m-3 flex justify-center flex-col'>
                <img className='rounded-full h-2/6' alt='' src={`https://api.growve.tools:8393/landingPageImages/${props.reviewOne.src}`} />
                <div className='text-2xl font-bold text-black'>{props.reviewOne.title}</div>
                <div className='flex gap-x-0.5 h-5 text-yellow-400 justify-center items-center'>
                    <StarIcon className="h-5 w-5 flex-none" aria-hidden="true" />
                    <StarIcon className="h-5 w-5 flex-none" aria-hidden="true" />
                    <StarIcon className="h-5 w-5 flex-none" aria-hidden="true" />
                    <StarIcon className="h-5 w-5 flex-none" aria-hidden="true" />
                    <StarIcon className="h-5 w-5 flex-none" aria-hidden="true" />
                </div>
                <div className='text-lg px-4 text-black font-medium'>{props.reviewOne.text}</div>
                <div className='text-lg text-gray-500 font-medium italic'>- {props.reviewOne.name}</div>
            </div>
            <div className='m-3'>
                <img className='rounded-full' alt='' src={`https://api.growve.tools:8393/landingPageImages/${props.reviewTwo.src}`} />
                <div className='text-2xl font-bold text-black'>{props.reviewTwo.title}</div>
                <div className='flex gap-x-0.5 h-5 text-yellow-400 justify-center items-center'>
                    <StarIcon className="h-5 w-5 flex-none" aria-hidden="true" />
                    <StarIcon className="h-5 w-5 flex-none" aria-hidden="true" />
                    <StarIcon className="h-5 w-5 flex-none" aria-hidden="true" />
                    <StarIcon className="h-5 w-5 flex-none" aria-hidden="true" />
                    <StarIcon className="h-5 w-5 flex-none" aria-hidden="true" />
                </div>
                <div className='text-lg px-4 text-black font-medium'>{props.reviewTwo.text}</div>
                <div className='text-lg text-gray-500 font-medium italic'>- {props.reviewTwo.name}</div>
            </div>
            <div className='m-3'>
                <img className='rounded-full' alt='' src={`https://api.growve.tools:8393/landingPageImages/${props.reviewThree.src}`} />
                <div className='text-2xl font-bold text-black'>{props.reviewThree.title}</div>
                <div className='flex gap-x-0.5 h-5 text-yellow-400 justify-center items-center'>
                    <StarIcon className="h-5 w-5 flex-none" aria-hidden="true" />
                    <StarIcon className="h-5 w-5 flex-none" aria-hidden="true" />
                    <StarIcon className="h-5 w-5 flex-none" aria-hidden="true" />
                    <StarIcon className="h-5 w-5 flex-none" aria-hidden="true" />
                    <StarIcon className="h-5 w-5 flex-none" aria-hidden="true" />
                </div>
                <div className='text-md px-4 text-black font-medium'>{props.reviewThree.text}</div>
                <div className='text-lg font-medium text-gray-500 italic'>- {props.reviewThree.name}</div>
            </div>
        </div>
    )
};

function SignUp(props) {
    return (
        <div id={props.id} className='grid pb-6 mx-3 rounded-lg' style={{ backgroundColor: props.bgColor }}>
            <h2 className='py-3 text-3xl font-bold rounded-lg' style={{ backgroundColor: props.headerBg, color: props.fontColor }}>{props.headerText}</h2>
            <div id='emailSignUp' className='justify-center grid grid-cols-3 w-full'>
                <div className='flex lg:w-full w-1/2 lg:col-span-1 lg:col-start-2 col-span-3 justify-self-center my-3 pt-6'>
                        <input
                            type="text"
                            name="first name"
                        id="firstName"
                        value={props.firstName}
                        onChange={props.firstNameChange}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="First Name"
                        />
                </div>
                <div className='flex lg:w-full w-1/2 lg:col-span-1 lg:col-start-2 col-span-3 justify-self-center my-3'>
                        <input
                            type="email"
                            name="email"
                        id="email"
                        value={props.email}
                        onChange={props.emailChange}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Email Address*"
                        />
                
                </div>
                <div className='flex lg:w-full lg:col-span-1 lg:col-start-2 col-span-3 justify-center my-3'>
                    <button
                        style={{ backgroundColor: props.buttonColor, opacity: (props.disableSignUp ? '0.6' : '1') }}
                        className='block p-3 rounded-xl text-white font-bold justify-self-center'
                        disabled={props.disableSignUp}
                        onClick={() => { props.signUp() } }
                    >
                        {props.buttonText}
                    </button>
                </div>
            </div>
        </div>
    )
};
function ThankYou(props) {
    return (
        <div id={props.id} className='grid pb-6 mx-3 rounded-lg' style={{ backgroundColor: props.bgColor }}>
            <h2 className='py-3 text-3xl font-bold rounded-lg' style={{ backgroundColor: props.headerBg, color: props.fontColor }}>{props.headerText}</h2>
            <div className='flex justify-self-center w-1/2 my-3 text-2xl pt-6 items-center text-center justify-center' style={{ height: props.thankYouHeight, color: props.fontColor } }>
                {props.thankYouText}
            </div>
        </div>
    )
};
function EmailSignUp(props) {
    const [firstName, setFirstName] = useState('');
    const [email, setEmail] = useState('');
    const [valid, setValid] = useState({
        firstName: false,
        email: false
    });
    const [complete, setComplete] = useState(false);
    const [thankYouHeight, setThankYouHeight] = useState('');

    function getThankYouHeight() {
        var el = document.getElementById('emailSignUp');
        var height = el.offsetHeight;
        setThankYouHeight(height);
    }

    function emailValidation(email) {
        if (email.includes('@')) {
            const emailParts = email.split('@')
            let domainParts;
            if (emailParts.length > 1) {
                domainParts = emailParts[1].split('.');
            } else {
                domainParts = ['']
            };
            let validEmail;
            if (emailParts.length === 2 && emailParts[0].length >= 2 && domainParts[0].length >= 2 && domainParts.length >= 2 && domainParts[1].length >= 2) {
                validEmail = true;
            } else {
                validEmail = false;
            };
            return validEmail;
        } else {
            return false;
        };
    };

    function updateEmail(email) {
        setEmail(email);
        setValid({
            ...valid,
            email: emailValidation(email)
        })
        getThankYouHeight();
    };

    function updateName(name) {
        setFirstName(name);
        setValid({
            ...valid,
            firstName: true
        });
    };

    function signUp() {
        //fetch('https://api.growve.tools:8393/addToKlaviyoList', { method: 'POST', mode: 'cors', body: JSON.stringify({ brand: props.brand, email: email, firstName: firstName }) })
        //    .then(response => { if (response.ok) { setComplete(true) } })
        setComplete(true);
    };

    if (!complete) {
        return (
            <SignUp
                id={props.id}
                bgColor={props.bgColor}
                fontColor={props.fontColor}
                headerBg={props.headerBg}
                headerText={props.signUpHeader}
                buttonText={props.buttonText}
                buttonColor={props.buttonColor}
                signUp={signUp}
                emailChange={(e) => updateEmail(e.target.value)}
                firstNameChange={(e) => updateName(e.target.value)}
                firstName={firstName}
                email={email}
                disableSignUp={valid.email && valid.firstName ? false : true}
            />
        )
    } else {
        return (
            <ThankYou
                id={props.id}
                bgColor={props.bgColor}
                fontColor={props.fontColor}
                headerBg={props.headerBg}
                headerText={props.thankYouHeader}
                thankYouText={props.thankYouText}
                thankYouHeight={thankYouHeight}
            />
        )
    }
    
};

function BenefitBlock(props) {
    return (
        <div id={props.id} className='grid grid-cols-4 gap-x-6 content-start px-12 lg:px-24 items-start'>
            <div className='col-span-4 text-3xl font-bold py-5 flex justify-center'>
                {props.header}
            </div>
            {props.benefits.map(benefit => {
                return (
                    <div className='lg:col-span-1 col-span-4 flex flex-col flex-wrap justify-center content-center items-start py-6'>
                        <img className='rounded-md flex justify-center mx-auto' style={{maxHeight: 350}} src={`https://api.growve.tools:8393/landingPageImages/${benefit.src}`} alt='' />
                        <h4 className='p-3 font-bold underline text-xl mx-auto'>{benefit.title}</h4>
                        <span className='text-md'>{benefit.text}</span>
                    </div>
                )
            }) }
        </div>
    )
};

function ImageWithHeader(props) {
    return (
        <div id={props.id} className='grid grid-cols-1'>
            <div className='col-span-1 text-3xl font-bold p-6'>{props.header}</div>
            <img className='w-full' src={`https://api.growve.tools:8393/landingPageImages/${props.src}`} alt='' />
        </div>
    )
};

function TextWithHeader(props) {
    const [padding] = useState(props.child ? 'grid grid-cols-1' : 'grid grid-cols-1 px-12 lg:px-24');
    return (
        <div id={props.id} className={padding}>
            <div className='col-span-1 text-3xl font-bold p-6' style={{textAlign: props.textAlign}}>{props.header}</div>
            <div className='col-span-1 text-xl px-6' style={{ textAlign: props.textAlign }}>{props.text}</div>
        </div>
    )
};

function Image(props) {
    return (
        <img className={props.className} style={{maxHeight: '33rem'}} loading='lazy' src={`https://api.growve.tools:8393/landingPageImages/${props.src}`} alt={props.alt ? props.alt : ''} />
    )
}

function ImageCarousel(props) {
    return (
        <Tab.Group>
            <Tab.Panels>
                {props.images.map(image => {
                    return (
                        <Tab.Panel><img className='h-full w-auto' loading='lazy' src={`https://api.growve.tools:8393/landingPageImages/${image}`} alt='' /></Tab.Panel>
                    )
                })}
            </Tab.Panels>
            <Tab.List>
                {props.images.map(image => {
                    return (
                        <Tab><img className='h-16 w-auto' loading='lazy' src={`https://api.growve.tools:8393/landingPageImages/${image}`} alt='' /></Tab>
                    )
                }) }
            </Tab.List>
            
        </Tab.Group>
    )
}

function Images(props) {
    if (props.images.length === 1) {
        return <Image src={props.images[0]} />
    } else if (props.images.length > 1) {
        return <ImageCarousel images={props.images} />
    } else if (props.images.length < 1) {
        return;
    }
};

function PriceTag(props) {
    if (props.prices.length === 1) {
        return (
            <>
                <div className='italic font-semibold text-lg mt-4 px-6'>Price:</div>
                <div className='font-bold text-lg mb-4 px-6'>{props.price[0]}</div>
            </>
        )
    } else if (props.prices.length === 2) {
        var lowest = props.prices[0] > props.prices[1] ? props.prices[1] : props.prices[0];
        var highest = props.prices[0] > props.prices[1] ? props.prices[0] : props.prices[1];
        return (
            <>
                <div className='italic justify-self-start font-semibold text-lg text-start mt-4 px-6'>Retail Price:</div>
                <div className='font-bold justify-self-start text-lg text-start opacity-70 px-6' style={{textDecoration: 'line-through'} }>{highest}</div>
                <div className='italic justify-self-start font-semibold text-lg text-start px-6'>Your Price:</div>
                <div className='font-bold justify-self-start text-lg text-start mb-4 px-6'>{lowest}</div>
            </>
        )
    }
};

function Quantity(props) {
    return (
        <div id={props.id} className='flex my-4'>
            <button
                onClick={props.minus}
                className='bg-slate-200 p-3 rounded-l-md border-gray-500 disabled:bg-slate-600 disabled:text-white'
                disabled={props.disableMinus}
            >
                <MinusIcon className='h-4 w-4 text-inherit' />
            </button>
            <div
                className='text-center border-t border-b border-gray-300 py-3 px-5'
            >
                {props.count}
            </div>
            <button
                onClick={props.plus}
                className='bg-slate-200 p-3 rounded-r-md border-gray-500 disabled:bg-slate-500 disabled:text-white'
                disabled={props.disablePlus}
            >
                <PlusIcon className='h-4 w-4' />
            </button>
        </div>
    )
};

function ShopifyBuyButton(props) {
    const [url, setUrl] = useState(`${props.url}:${props.quantity}`);

    useEffect(() => {
        function updateUrl() {
            setUrl(`${props.url}:${props.quantity}`);
        };
        updateUrl();
    }, [props]);


    return (
        <a
            id={props.id}
            className='rounded-lg p-4 mt-4 w-1/2'
            href={url}
            style={{ backgroundColor: props.buttonColor, color: props.fontColor }}

        >{props.buttonText}</a>
            
    )
}
function PurchaseBlock(props) {
    const [quantity, setQuantity] = useState(1);
    const [baseUrl, setBaseUrl] = useState(`${props.shopifyUrl}/cart/${props.variantId}`);
    return (
        <div id={props.id} className='grid grid-cols-1 lg:grid-cols-2 px-12 lg:px-24 gap-x-6 justify-items-center items-center py-4'>
            <div className='rounded-lg border-2 border-gray-100'>
                <Images images={props.images} />
            </div>
            <div className='grid grid-cols-1 pl-6 justify-items-center'>
                <TextWithHeader
                    textAlign='left'
                    child
                    header='Saw Palmetto for Women with Biotin, 60ct'
                    text='You will definitely love this product! Saw palmetto + Biotin can support and restore hair health and growth. First to combine these two ingredients, Havasu Nutrition wants to help reduce effects of aging for women.'
                />
                <PriceTag
                    prices={props.prices}
                />
                <Quantity
                    count={quantity}
                    minus={() => { setQuantity(prev => prev -= 1) }}
                    plus={() => { setQuantity(prev => prev += 1) }}
                    disableMinus={quantity <= 1}
                />
                    
                <ShopifyBuyButton
                    url={baseUrl}
                    quantity={quantity}
                    buttonText='Buy Now'
                    buttonColor='#F9A61B'
                    fontColor='white'
                />
                    
            </div>
        </div>
    )
};

function FooterSocial(props) {
    
    return (
        <footer id={props.id} className="grid grid-cols-12" style={{backgroundColor: (props.bgColor ? props.bgColor : 'white'), color: (props.fontColor ? props.fontColor : 'black')}}>
            <a href='https://havasunutrition.com' className='flex w-full pt-5 col-span-2 pl-3 items-center'>
                <img style={{ maxHeight: props.logoHeight }} src={`https://api.growve.tools:8393/landingPageImages/${props.logo}`} alt='' />
            </a>
            <div className="mx-auto max-w-7xl overflow-hidden px-6 py-5 sm:py-5 lg:px-8 col-span-8 flex items-center">
                <nav className="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12" aria-label="Footer">
                    {props.navigation.main.map((item) => (
                        <div key={item.name} className="pb-6">
                            <a href={item.href} className="text-sm leading-6 text-white hover:text-gray-200">
                                {item.name}
                            </a>
                        </div>
                    ))}
                </nav>

            </div>
            <div className="mt-5 flex items-center justify-center space-x-10 col-span-1 mr-3">
                {props.navigation.social.map((item) => (
                    <a key={item.name} href={item.href} className="text-white hover:text-gray-200">
                        <span className="sr-only">{item.name}</span>
                        {getIcons(item.name, { className: 'h-6 w-6', fill: (props.fontColor ? props.fontColor : 'black')}) }
                    </a>
                ))}
            </div>
            <p className="text-center text-xs leading-5 text-white col-span-12">
                {props.copyrightText}
            </p>
        </footer>
    )
};



function FAQs(props) {
    return (
        <div className="bg-white">
            <div className="mx-auto max-w-7xl px-6 py-12 lg:px-8">
                <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
                    <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
                    <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                        {props.faqs.map((faq) => (
                            <Disclosure as="div" key={faq.question} className="pt-6">
                                {({ open }) => (
                                    <>
                                        <dt>
                                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                                                <span className="text-base font-semibold leading-7">{faq.question}</span>
                                                <span className="ml-6 flex h-7 items-center">
                                                    {open ? (
                                                        <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                    ) : (
                                                        <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                    )}
                                                </span>
                                            </Disclosure.Button>
                                        </dt>
                                        <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                            <p className="text-base leading-7 text-gray-600 text-left pl-6">{faq.answer}</p>
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
};

function Disclaimer(props) {
    return (
        <div
            className='w-full justify-center text-center italic p-6 text-sm'
            style={{
                backgroundColor: props.bgColor,
                color: props.fontColor
            }}
        >
            <sub>*{props.disclaimerText}</sub>
        </div>
    )
}





export { FocusProduct, Icons, LogoBanner, CallToAction, ThreeReviewBlock, SignUp, BenefitImageBlock, FAQs, BenefitBlock, ImageWithHeader, EmailSignUp, TextWithHeader, Images, PriceTag, Quantity, PurchaseBlock, FooterSocial, Disclaimer }
