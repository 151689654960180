import { useState, useEffect } from 'react';
import { FocusProduct, Icons, LogoBanner, CallToAction, ThreeReviewBlock, SignUp, BenefitImageBlock, FAQs, BenefitBlock, ImageWithHeader, EmailSignUp, TextWithHeader, Images, PriceTag, Quantity, PurchaseBlock, FooterSocial, Disclaimer } from './LandingPageComponents'
import Hero from './Hero';
function getComponent(component) {
    switch (component.type) {
        case 'LogoBanner':
            return <LogoBanner key={`lp_component_${component.order}`} {...component.props} />
        case 'FocusProduct':
            return <FocusProduct key={`lp_component_${component.order}`} {...component.props} />
        case 'Icons':
            return <Icons key={`lp_component_${component.order}`} {...component.props} />
        case 'BenefitBlock':
            return <BenefitBlock key={`lp_component_${component.order}`} {...component.props} />
        case 'CallToAction':
            return <CallToAction key={`lp_component_${component.order}`} {...component.props} />
        case 'EmailSignUp':
            return <EmailSignUp key={`lp_component_${component.order}`} {...component.props} />
        case 'ThreeReviewBlock':
            return <ThreeReviewBlock key={`lp_component_${component.order}`} {...component.props} />
        case 'BenefitImageBlock':
            return <BenefitImageBlock key={`lp_component_${component.order}`} {...component.props} />
        case 'FAQs':
            return <FAQs key={`lp_component_${component.order}`} {...component.props} />
        case 'ImageWithHeader':
            return <ImageWithHeader key={`lp_component_${component.order}`} {...component.props} />
        case 'TextWithHeader':
            return <TextWithHeader key={`lp_component_${component.order}`} {...component.props} />
        case 'Images':
            return <Images key={`lp_component_${component.order}`} {...component.props} />
        case 'PurchaseBlock':
            return <PurchaseBlock key={`lp_component_${component.order}`} {...component.props} />
        case 'Footer':
            return <FooterSocial key={`lp_component_${component.order}`} {...component.props} />
        case 'Hero':
            return <Hero key={'solo'} />
        case 'Disclaimer':
            return <Disclaimer key={`lp_component_${component.order}`} {...component.props} />
        case 'backend':
            return;
        default:
            return;
    }
}
export default function LandingPage(props) {
    const [domain] = useState(window.location.hostname);
    const [path] = useState(window.location.pathname);
    const [pageInfo, setPageInfo] = useState(null);
    const [domainInfo, setDomainInfo] = useState(null);
    const [components, setComponents] = useState(null);
    const [font, setFont] = useState('');

    useEffect(() => {
        function getPageInfo() {
            if (domain !== 'landing.growve.tools') {
                var usableDomain = (domain === 'localhost' ? 'promo.havasuoffers.com' : domain);
                var usablePath = (path === '/' ? '/path' : path);
                console.log(`https://api.growve.tools:8393/landingPages/${usableDomain}${usablePath}`);
                fetch(`https://api.growve.tools:8393/landingPages/${usableDomain}${usablePath}`, {
                    method: 'GET',
                    mode: 'cors'
                })
                    .then(response => {
                        return response.json();
                    })
                    .then(json => {
                        var resp = json;
                        setDomainInfo(resp.domainInfo);
                        var favicon = document.getElementById('favicon_tag');
                        favicon.href = `https://api.growve.tools:8393/landingPageImages/${resp.domainInfo.directory}/favicon.png`;
                        if (resp.exists === true) {
                            setPageInfo(resp.pageInfo);
                            setComponents(resp.pageInfo.components);
                            setFont(`font-${resp.pageInfo.brand}`)
                        } else if (resp.exists === false) {
                            window.location.href = resp.domainInfo.redirect;
                        }
                    })
            } else if (domain === 'landing.growve.tools') {
                setComponents([{type: 'backend'}])
            }
        };
        getPageInfo();
    }, [domain, path])

    if (components !== null && components.length > 0) {
        return (
            <div className={font}>
                {components.map(component => {
                    return (getComponent(component))
                })}
            </div>
        )
    } else {
        return null;
    }
    
};