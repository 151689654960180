import logo from './logo.svg';
import './App.css';
import './index.css';
import { useState, useEffect } from 'react';
import { ScrollingBanner, BenefitsWithImage, BenefitsWrapper, ResponsiveIconBar } from './Components/BasePageComponents';
import { BenefitList, Image, ReviewOverview } from './Components/BaseComponents';
import { IconBarForm } from './Components/BuilderFormComponents';
import { BannerForm } from './Components/BuilderForms';
import { DragMarqueeForm } from './Components/DraggableFormComponents';
import CompetitorTable from './Components/CompetitorTable';
import CompetitorTableForm from './Components/CompetitorTableForm';
import { Grid, Col } from '@tremor/react';
import { CTAWithImage } from './Components/CTAComponents';
import { CTAForm } from './Components/FormComponents/CTAForm';
import { TremorTest } from './TremorTest';
import FAQ from './Components/FAQ';
import { Banner, BannerWithButton } from './Components/Banners';
import { StatsSimple } from './Components/Stats';
import Reviews from './Components/Reviews';
import FeaturesSimple from './Components/Features';
import { ProductFeaturesSplit, FeaturesAlternating, FeaturesStacked } from './Components/ProductFeatures';
import BuilderContainer from './Components/BuilderContainer';
import ToolTip from './Components/ToolTip';
import AddComponent from './Components/AddComponentBar';
import ComponentPreview from './Components/ComponentPreview';
import Header from './Components/Header';
import ReviewMarquee from './Components/ReviewMarquee';
import Hero from './Components/Hero';
import { Helmet } from 'react-helmet';
import promo from './promo.jpg';
import { FocusProduct, Icons, LogoBanner, CallToAction, ThreeReviewBlock, EmailSignUp, BenefitImageBlock, FAQs, BenefitBlock, ImageWithHeader, TextWithHeader, Images, PriceTag, Quantity, PurchaseBlock, FooterSocial } from './Components/LandingPageComponents';
import sawPalm from './sawPalm.png';
import Example from './Components/MenuTest';
import LandingPage from './Components/LandingPage';

function App() {
    const [descriptions] = useState([
        'Boost your performance with L-arginine!',
        'Unlock your potential with L-arginine supplementation.',
        'Enhance your workouts with the power of L-arginine.',
    ]);

    useEffect(() => {
        
        console.log(promo);
    }, [])
    
    const [showToolTip, setShowToolTip] = useState(false);
    const [toolTip, setToolTip] = useState({
        header: '',
        text: ''
    });
    
  return (
      <div
          className="App"
          id='App'
      >
          <LandingPage />
{/*          <LogoBanner homePage='https://havasunutrition.com' bgColor='rgb(191,103,154)' logo='havasu/logoWhite.png' logoHeight={30}  />*/}
{/*          <FocusProduct*/}
{/*              product={{*/}
{/*                  name: 'Naturally Grow Your Hair in as Fast as 90 Days With Saw Palmetto For Women with Biotin',*/}
{/*                  price: '$26.29',*/}
{/*                  description: 'Our #1 Best Selling Saw Palmetto is made better by combining 5000mcg of Biotin to help combat hair loss and foster hair growth. Saw Palmetto tackles the hormone DHT, a major culprit behind hair thinning and baldness, creating the ideal environment for hair follicle health and revitalization[*]. With research-backed ingredients showing improvement of female and male hair loss, other benefits also include:',*/}
{/*                  details: ['Lower Urinary Tract Symptoms (LUTS) in women [*]', 'Trouble with Frequent Urination?', 'Hormonal Balance', 'Improve Overall Hair, Skin & Nail Health'],*/}
{/*                  image: 'havasu/lp_women_saw_palmetto_product.png'*/}
{/*              }}*/}
{/*              buttonText='View More'*/}
{/*              buttonColor='#F9A61B'*/}
{/*              buttonLink='#callToAction'*/}
{/*          />*/}
{/*          <Icons*/}
{/*              headerText='Common Causes of Hair Loss for Women'*/}
{/*              headerBg='rgb(191,103,154)'*/}
{/*              headerColor='text-white'*/}
{/*              icons={*/}
{/*                  [*/}
{/*                      { name: 'DIET & LIFESTYLE', src: 'havasu/lp_diet.png' },*/}
{/*                      { name: 'EXCESS MALE HORMONES', src: 'havasu/lp_hormones.png' },*/}
{/*                      { name: 'AGE', src: 'havasu/lp_age.png' },*/}
{/*                      { name: 'STRESS', src: 'havasu/lp_stress.png' },*/}
{/*                      { name: 'GENETICS', src: 'havasu/lp_dna.png' }*/}
{/*                  ]*/}
{/*              }*/}
{/*          />*/}
{/*          <BenefitBlock*/}
{/*              header='4 Benefits of Saw Palmetto for Women'*/}
{/*              benefits={[*/}
{/*                  { title: "Promotes Healthy Hair Growth", text: "Saw Palmetto combined with Biotin can be a game-changer for women experiencing hair thinning or loss. These natural ingredients work together to support robust hair growth by reducing the impact of DHT (dihydrotestosterone), a hormone often linked to hair problems. This combination helps maintain fuller and healthier locks, boosting confidence.", src: 'havasu/lp_promote_healthy_hair_growth.jpg' },*/}
{/*                  { title: "Supports Skin and Nail Health", text: "Biotin, a B-vitamin, contributes to glowing skin and stronger nails, providing a holistic beauty solution.", src: "havasu/lp_support_skin_and_nail_health.jpg" },*/}
{/*                  { title: "Help Manage Hormonal Imbalance", text: "Saw Palmetto may aid in hormonal balance for women, particularly in managing conditions like Polycystic Ovary Syndrome (PCOS). By helping regulate hormones, it can alleviate symptoms such as excessive hair growth or hair loss associated with hormonal imbalances*", src: "havasu/lp_manage_imbalance.jpg" },*/}
{/*                  { title: "Relief from Frequent Urination & UTI Symptoms", text: "Saw Palmetto's anti-inflammatory properties may help reduce urinary tract inflammation, easing frequency of bathroom visits and alleviate discomfort. This natural combination not only promotes hair and beauty but also enhances women's overall quality of life by addressing urinary concerns.", src: "havasu/lp_relief_from_frequent_urination.jpg"}*/}
{/*              ]}*/}
{/*            />*/}
{/*          <CallToAction*/}
{/*              title='Beating the Statistics'*/}
{/*              text="Statistics reveal that over 50% of women have encountered hair */}
{/*loss at some point in their lives, facing issues such as frontal baldness, */}
{/*thinning hair, or a widening hair part. According to the American Dermatology, */}
{/*40% of women have visible hair loss by the time they turn 40. It's essential */}
{/*to recognize that hair loss in women is a prevalent concern, */}
{/*but the good news is that it's often preventable and manageable."*/}
{/*              buttonText='Try It Now - Risk Free!'*/}
{/*              buttonColor='#F9A61B'*/}
{/*              src='havasu/lp_saw_palmetto_ugc1.jpg'*/}

{/*          />*/}
{/*          <EmailSignUp*/}
{/*              bgColor='rgb(191,103,154)'*/}
{/*              headerBg='#AC127C'*/}
{/*              signUpHeader='Sign Up For 20% OFF'*/}
{/*              thankYouHeader='Thank you for signing up!'*/}
{/*              thankYouText='Check your email for your 20% off coupon!'*/}
{/*              fontColor='white'*/}
{/*              buttonText='Sign Me Up For A Discount!'*/}
{/*              buttonColor="#1287FF"*/}
{/*          />*/}
{/*          <ThreeReviewBlock*/}
{/*              reviewOne={{*/}
{/*                  title: 'Best Saw Palmetto',*/}
{/*                  text: "So I am in my 30s & a few months ago I had a lot of hair fall out -Yikes!! So I tried a lots of supplements- but I must say this is by far the best on my 3rd bottle & now I can count my hairs on my brush & shower- Hair fall out is no joke ladies so we need to use the right hair brush, right shampoo/conditioner & a DYI rosemary hair rinse, statin/silk pillow cover & do not pull or yank on your hair, do tight pony tails!?",*/}
{/*                  src: 'havasu/lp_saw_palmetto_review_1.png',*/}
{/*                  name: 'Grace'*/}
{/*              }}*/}
{/*              reviewTwo={{*/}
{/*                  title: 'Female Hair Growth',*/}
{/*                  text: "I've been using this for about 5 months. It is a miracle supplement.",*/}
{/*                  src: 'havasu/lp_saw_palmetto_review_2.png',*/}
{/*                  name: 'Amazon Customer'*/}
{/*              }}*/}
{/*              reviewThree={{*/}
{/*                  title: 'Love it',*/}
{/*                  text: "This really works for hair growth for me. It takes about 3 months before you notice the hair growth. Mine was about a 1/4 inch from the scalp. My hairdresser showed me how much hair was coming through my scalp. My hair stopped falling out after about a month. I've been on the formula for6 months now and my new growth is about 1 1/2 inches now. So impressed. My sister has been taking it for 2 years and has really thick hair. This is no joke.",*/}
{/*                  src: 'havasu/lp_saw_palmetto_review_3.png',*/}
{/*                  name: 'Amazon Customer'*/}
{/*              }}*/}
{/*          />*/}
{/*          <BenefitImageBlock*/}
{/*              src='havasu/lp_saw_palmetto_before_after.png'*/}
{/*              benefits={[{*/}
{/*                  title: 'Saw Palmetto', text: "Saw Palmetto is your hair's best friend.This natural powerhouse ingredient is known for its ability to inhibit DHT, a hormone that's often responsible for hair loss. By curbing DHT's impact, Saw Palmetto creates a nurturing environment for your hair follicles to thrive, resulting in thicker, healthier hair."*/}
{/*              }, { title: "Biotin", text: 'Biotin, often referred to as the "beauty vitamin," is a key player in the quest for vibrant hair. It strengthens hair follicles, reduces breakage, and improves overall hair quality. With Biotin on your side, you can expect to see stronger and more resilient strands that shine with vitality.' }]}*/}
{/*          />*/}

{/*          <FAQs*/}
{/*              faqs={[*/}
{/*                  {*/}
{/*                      question: "How does Saw Palmetto benefit women's hair health?",*/}
{/*                      answer: "Saw Palmetto may help inhibit the hormone DHT, which is often linked to hair loss in women. By reducing DHT's impact, it supports healthier, thicker hair."*/}
{/*                  },*/}
{/*                  {*/}
{/*                      question: "What is the recommended serving size, and how long does one bottle last?",*/}
{/*                      answer: "The recommended serving is 2 capsules per day. One bottle typically provides 30 days of usage."*/}
{/*                  },*/}
{/*                  {*/}
{/*                      question: "When can I expect to see results from using Havasu Nutrition Saw Palmetto with Biotin?",*/}
{/*                      answer: "While individual results may vary, some users notice improvements in as little as 90 days with consistent daily use."*/}
{/*                  },*/}
{/*                  {*/}
{/*                      question: "Is this vegan?",*/}
{/*                      answer: "Yes, Havasu Nutrition Saw Palmetto with Biotin is vegan and non-GMO."*/}
{/*                  },*/}
{/*                  {*/}
{/*                      question: "Where was this made?",*/}
{/*                      answer: "Our products are formulated in the USA. We send our products to an outside lab to ensure it is meeting standards of the dietary community."*/}
{/*                  }*/}
{/*              ]}*/}
{/*          />*/}
{/*          <ImageWithHeader*/}
{/*              header='See Significant Results in 90 Days!'*/}
{/*              src='havasu/lp_saw_palmetto_instructions.jpg'*/}
{/*          />*/}
{/*          <TextWithHeader*/}
{/*              header='30-Day Money Back Guarantee'*/}
{/*              text={<>We recommend giving it at least 90 days of continued use to see significant result. However, if for any reason you' re not satisfied with your purchase, simply contact our customer support team within 30 days of your order date, and we'll provide you with a full refund, no questions asked. Your satisfaction is our priority, and we want you to experience the benefits of our product with confidence. <br /><br /> <i>Terms and conditions apply. Please refer to our refund policy for complete details.</i></>}*/}
{/*          />*/}
          
{/*          <PurchaseBlock*/}
{/*              images={['havasu/lp_women_saw_palmetto_product.png']}*/}
{/*              prices={['$28.99', '$26.29']}*/}
{/*              shopifyUrl='https://havasunutrition.com'*/}
{/*              variantId='40593926815788'*/}
{/*          />*/}
{/*          <FooterSocial*/}
{/*              navigation={{*/}
{/*                  main: [*/}
{/*                      { name: 'Our Story', href: 'https://havasunutrition.com/pages/our-story' },*/}
{/*                      { name: 'Blog', href: 'https://havasunutrition.com/blogs/blog' },*/}
{/*                      { name: "FAQ's", href: 'https://havasunutrition.com/pages/faqs' },*/}
{/*                      { name: 'Privacy Policy', href: 'https://havasunutrition.com/pages/privacy-policy' },*/}
{/*                      { name: 'Terms of Use', href: 'https://havasunutrition.com/pages/terms-conditions' },*/}
{/*                      { name: 'Contact Us', href: 'https://havasunutrition.com/pages/contact-us' },*/}
{/*                  ],*/}
{/*                  social: [*/}
{/*                      {*/}
{/*                          name: 'Instagram',*/}
{/*                          href: 'https://www.instagram.com/havasunutrition/'*/}
{/*                      },*/}
{/*                      {*/}
{/*                          name: 'Pinterest',*/}
{/*                          href: 'https://www.pinterest.com/havasunutrition/'*/}
{/*                      },*/}
{/*                  ],*/}
{/*              }}*/}
{/*              bgColor='rgb(191,103,154)'*/}
{/*              fontColor='white'*/}
{/*              copyrightText='&copy; Havasu Nutrition, 2023. All Rights Reserved.'*/}
{/*              logo='havasu/logoWhite.png'*/}
{/*              logoHeight={30}*/}
{/*              />*/}
      {/*    <Helmet>*/}
      {/*        <meta name='description' content="For the ultimate male physique and performance, you can dominate your life by adding Havasu's l arginine supplement to your daily routine." />*/}
      {/*        <title>{ descriptions[Math.floor(Math.random() * descriptions.length)]}</title>*/}
      {/*        <meta property='og:image' content={promo} />*/}
      {/*        <meta property='og:title' content={descriptions[Math.floor(Math.random() * descriptions.length)]} />*/}
      {/*        <meta property='og:description' content="For the ultimate male physique and performance, you can dominate your life by adding Havasu's l arginine supplement to your daily routine." />*/}
      {/*        <meta charset="utf-8" />*/}
      {/*        <link id="favicon" rel="icon" href="https://api.growve.tools:8393/getFavicon/havasuoffers/acv-gift" />*/}
      {/*        <link rel="apple-touch-icon" href="https://api.growve.tools:8393/getFavicon/havasuoffers/acv-gift" />*/}
      {/*    </Helmet>*/}
      {/*<Hero />*/}
          {/*<ReviewMarquee*/}
          {/*    reviews={*/}
          {/*        [*/}
          {/*            "I've been using Havasu Nutrition's L-arginine for a few months now, and it has made a noticeable difference in my athletic performance. I feel like I have more endurance during workouts and faster recovery afterward. Highly recommended for anyone looking to take their athletic performance to the next level!",*/}
          {/*            "I started taking L-Arginine to support my cardio workouts, and I'm amazed at the results. My energy levels have increased, and I can push myself harder during intense training sessions. I've noticed improved blood flow, and my muscles feel more pumped. It's become a staple in my supplement regimen.",*/}
          {/*            "As a competitive runner, I'm always looking for ways to improve my performance. Incorporating L-arginine into my training routine has been a game-changer. It helps me maintain optimal blood flow, allowing me to run longer distances without feeling fatigued. I've seen improvements in my race times, and I feel more confident in my abilities.",*/}
          {/*            "L-Arginine has become my go-to supplement for athletic support. Since I started taking it, my workouts have been more intense and focused. I feel a significant boost in my endurance, allowing me to push through tough training sessions. It's a must-have for any serious athlete."*/}
          {/*        ]*/}
          {/*    }*/}
          {/*    />*/}
          {/*<Header />*/}
          
          {/*<ToolTip*/}
          {/*    header={toolTip.header}*/}
          {/*    text={toolTip.text}*/}
          {/*    show={showToolTip}*/}
          {/*    noShow={setShowToolTip}*/}
          {/*/>*/}
          {/*<BuilderContainer*/}
          {/*    showToolTip={setShowToolTip}*/}
          {/*    setToolTip={setToolTip}*/}
          {/*/>*/}
          
          
    </div>
  );
}

export default App;

