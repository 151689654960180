import defaultProps from './defaultProps';
function swapListItems(idOne, idTwo, list) {
    var others = list.filter(x => x.id !== idOne && x.id !== idTwo);
    var first = list.filter(x => x.id === idOne)[0];
    var second = list.filter(x => x.id === idTwo)[0];
    var newOne = {
        ...first,
        id: second.id
    };
    var newTwo = {
        ...second,
        id: first.id
    };
    var sorted = [...others, newOne, newTwo].sort((a, b) => a.id - b.id);
    return sorted;
};

function updateItemInList(list, itemId, field, value) {
    let focus = list.filter(x => x.id === itemId)[0];
    let others = list.filter(x => x.id !== itemId);
    let newFocus = {
        ...focus,
        [field]: value
    };
    let sorted = [...others, newFocus].sort((a, b) => a.id - b.id);
    return sorted;
};

function updateNestedItem(dict, itemId, outerField, innerField, value) {
    let item = dict[outerField].filter(x => x.id === itemId)[0];
    let others = dict[outerField].filter(x => x.id !== itemId);
    let updated = {
        ...item,
        [innerField]: value
    };
    let sorted = [...others, updated].sort((a, b) => a.id - b.id);
    let updatedDict = {
        ...dict,
        [outerField]: sorted
    }
    
    return updatedDict;
}

function builderReducer(state, action) {
    var focusItem;
    var focusInfo;
    var secondItem;
    var otherItems;
    var newItem;
    var newInfo;
    var swappedItem;
    var sortedItems;
    var newItems;
    var newIndex;
    var otherCompetitors;
    var focusCompetitor;
    var newCompetitors;
    var features;
    var newFeatures;

    switch (action.type) {
        case 'simpleUpdate':
            focusItem = state.filter(x => x.id === action.id)[0];
            focusInfo = focusItem.info;
            otherItems = state.filter(x => x.id !== action.id);
            newInfo = {
                ...focusInfo,
                [action.field]: action.value
            };
            newItem = {
                ...focusItem,
                info: newInfo
            };
            sortedItems = [...otherItems, newItem].sort((a, b) => a.id - b.id);
            return sortedItems
            
        case 'addToList':
            focusItem = state.filter(x => x.id === action.id)[0];

            focusInfo = focusItem.info
            otherItems = state.filter(x => x.id !== action.id);
            newInfo = {
                ...focusInfo,
                [action.field]: [...focusInfo[action.field], action.value]
            };
            newItem = {
                ...focusItem,
                info: newInfo
            }
            sortedItems = [...otherItems, newItem].sort((a, b) => a.id - b.id);
            
            return sortedItems
            
        case 'removeFromList':
            focusItem = state.filter(x => x.id === action.id)[0];
            focusInfo = focusItem.info
            otherItems = state.filter(x => x.id !== action.id);
            newInfo = {
                ...focusInfo,
                [action.field]: focusInfo[action.field].filter(x => x !== action.value)
            };
            newItem = {
                ...focusItem,
                info: newInfo
            }
            sortedItems = [...otherItems, newItem].sort((a, b) => a.id - b.id);
            return sortedItems

        case 'removeFromListById':
            focusItem = state.filter(x => x.id === action.id)[0];
            focusInfo = focusItem.info
            otherItems = state.filter(x => x.id !== action.id);
            newItems = [];
            for (let x = 0; x < focusInfo[action.field].length; x++) {
                if (focusInfo[action.field][x].id === action.id) {
                    continue
                } else if (focusInfo[action.field][x].id < action.id) {
                    newItems.push(focusInfo[action.field][x]);
                } else {
                    newIndex = {
                        ...focusInfo[action.field][x],
                        id: focusInfo[action.field][x].id - 1
                    };
                    newItems.push(newIndex);
                }
            }
            newInfo = {
                ...focusInfo,
                [action.field]: newItems.sort((a, b) => a.id - b.id)
            };
            newItem = {
                ...focusItem,
                info: newInfo
            }
            sortedItems = [...otherItems, newItem].sort((a, b) => a.id - b.id);
            return sortedItems
            
        case 'replaceInList':
            if (!action.value.includes(action.filter)) {
                focusItem = state.filter(x => x.id === action.id)[0];
                focusInfo = focusItem.info;
                otherItems = state.filter(x => x.id !== action.id);
                newInfo = {
                    ...focusInfo,
                    [action.field]: [...focusInfo[action.field].filter(x => !x.includes(action.filter))]
                };
                newItem = {
                    ...focusItem,
                    info: newInfo
                };
                sortedItems = [...otherItems, newItem].sort((a, b) => a.id - b.id);
                return sortedItems;
            } else {
                focusItem = state.filter(x => x.id === action.id)[0];
                focusInfo = focusItem.info;
                otherItems = state.filter(x => x.id !== action.id);
                newInfo = {
                    ...focusInfo,
                    [action.field]: [...focusInfo[action.field].filter(x => !x.includes(action.filter)), action.value]
                };
                newItem = {
                    ...focusItem,
                    info: newInfo
                };
                sortedItems = [...otherItems, newItem].sort((a, b) => a.id - b.id);
                return sortedItems;
            }
            
            
        case 'reorderComponents':
            focusItem = state.filter(x => x.id === action.id)[0];
            secondItem = state.filter(x => x.id === action.swap)[0];
            otherItems = state.filter(x => x.id !== action.id && x.id !== action.swap);
            newItem = {
                ...focusItem,
                id: secondItem.id
            };
            swappedItem = {
                ...secondItem,
                id: focusItem.id
            }
            sortedItems = [...otherItems, newItem, swappedItem].sort((a, b) => a.id - b.id);
            return sortedItems;
        case 'removeComponent':
            otherItems = state.filter(x => x.id !== action.id);
            newItems = [];
            for (let x = 0; x < otherItems.length; x++) {
                if (otherItems[x].id < action.id) {
                    newItems.push(otherItems[x]);
                } else {
                    newItem = {
                        ...otherItems[x],
                        id: otherItems[x].id - 1
                    };
                    newItems.push(newItem);
                }
            }
            sortedItems = newItems.sort((a, b) => a.id - b.id);
            if (action.trash !== undefined && action.trash !== null) {
                action.trash(state.filter(x => x.id === action.id)[0]);
            }
            return sortedItems;
        case 'reorderLowerComponents':
            focusItem = state.filter(x => x.id === action.id)[0];
            focusInfo = focusItem.info;
            otherItems = state.filter(x => x.id !== action.id);
            newInfo = {
                ...focusInfo,
                [action.field]: swapListItems(action.itemId, action.swap, focusInfo[action.field])
            };
            newItem = {
                ...focusItem,
                info: newInfo
            };
            
            sortedItems = [...otherItems, newItem].sort((a, b) => a.id - b.id);
            
            return sortedItems;
        case 'restoreComponent':
            otherItems = [...state, action.component];
            return otherItems;
        case 'updateNestedComponent':
            focusItem = state.filter(x => x.id === action.id)[0];
            otherItems = state.filter(x => x.id !== action.id);
            focusInfo = focusItem.info;
            newItem = {
                ...focusItem,
                info: updateNestedItem(focusInfo, action.itemId, action.outerField, action.innerField, (action.number === true ? Number(action.value) : action.value))
            }
            sortedItems = [...otherItems, newItem].sort((a, b) => a.id - b.id);
            return sortedItems;
        case 'newComponent':
            newItem = {
                id: state.length,
                info: defaultProps[action.component],
                type: action.component
            };
            return [...state, newItem].sort((a, b) => a.id - b.id);
        case 'competitorTableNameChange':
            focusItem = state.filter(x => x.id === action.id)[0];
            otherItems = state.filter(x => x.id !== action.id);
            otherCompetitors = focusItem.info.competitors.filter(x => x.id !== action.compId);
            focusInfo = focusItem.info.competitors[action.compId];
            focusCompetitor = {
                ...focusInfo,
                name: action.name
            };
            newCompetitors = [...otherCompetitors, focusCompetitor].sort((a, b) => a.id - b.id);
            newItem = {
                ...focusItem,
                info: {
                    ...focusItem.info,
                    competitors: newCompetitors
                }
            };
            sortedItems = [...otherItems, newItem].sort((a, b) => a.id - b.id)
            return sortedItems;
        case 'changeUseImage':
            focusItem = state.filter(x => x.id === action.id)[0];
            otherItems = state.filter(x => x.id !== action.id);
            otherCompetitors = [focusItem.info.competitors[0], focusItem.info.competitors[2]];
            focusInfo = {
                ...focusItem.info.competitors[1],
                useImage: action.value
            };
            newCompetitors = [...otherCompetitors, focusInfo].sort((a, b) => a.id - b.id);
            newItem = {
                ...focusItem,
                info: {
                    ...focusItem.info,
                    competitors: newCompetitors
                }
            };
            sortedItems = [...otherItems, newItem].sort((a, b) => a.id - b.id);
            return sortedItems;
        case 'changeImageSource':
            focusItem = state.filter(x => x.id === action.id)[0];
            otherItems = state.filter(x => x.id !== action.id);
            otherCompetitors = [focusItem.info.competitors[0], focusItem.info.competitors[2]];
            focusInfo = {
                ...focusItem.info.competitors[1],
                imageSrc: action.value
            };
            newCompetitors = [...otherCompetitors, focusInfo].sort((a, b) => a.id - b.id);
            newItem = {
                ...focusItem,
                info: {
                    ...focusItem.info,
                    competitors: newCompetitors
                }
            };
            sortedItems = [...otherItems, newItem].sort((a, b) => a.id - b.id);
            return sortedItems;
        case "addFeature":
            focusItem = state.filter(x => x.id === action.id)[0];
            otherItems = state.filter(x => x.id !== action.id);
            newCompetitors = [
                {
                    ...focusItem.info.competitors[0],
                    features: {
                        ...focusItem.info.competitors[0].features,
                        ...action.value
                    }
                },
                {
                    ...focusItem.info.competitors[1],
                    features: {
                        ...focusItem.info.competitors[1].features,
                        ...action.value
                    }
                },
                {
                    ...focusItem.info.competitors[2],
                    features: {
                        ...focusItem.info.competitors[2].features,
                        ...action.value
                    }
                }
            ];
            newItem = {
                ...focusItem,
                info: {
                    ...focusItem.info,
                    competitors: newCompetitors
                }
            };
            sortedItems = [...otherItems, newItem].sort((a, b) => a.id - b.id);
            return sortedItems;
        case "reorderFeatures":
            focusItem = state.filter(x => x.id === action.id)[0];
            otherItems = state.filter(x => x.id !== action.id);
            features = Object.keys(focusItem.info.competitors[0].features);
            newFeatures = features.filter(x => x !== features[action.idx]);
            newFeatures.splice(action.newIdx, 0, features[action.idx]);
            newCompetitors = [];
            for (const y of focusItem.info.competitors) {
                let newSet = {
                    ...y,
                    features: {}
};
                for (const z of newFeatures) {
                    newSet.features[z] = y.features[z]
                };
                newCompetitors.push(newSet);
            };
            newItem = {
                ...focusItem,
                info: {
                    ...focusItem.info,
                    competitors: newCompetitors
                }
            };
            sortedItems = [...otherItems, newItem].sort((a, b) => a.id - b.id);
            return sortedItems;
        default:
            return state;
    }
};

export default builderReducer